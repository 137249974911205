<template>
      <el-dialog
        :title="title"
        :visible="visible"
        @close="closeModal()">

            <filter-organizer class="mt-4 row" id="element-container"  url=""
            :filters="currentFilter"></filter-organizer>

            <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="reset">Clear</button>&nbsp;
              <button class="btn btn-success" @click="exportItems">Export</button>
            </span>
      </el-dialog>
</template>

<script>


import {Dialog} from "element-ui";
import FilterOrganizer from '../FilterShared/FilterOrganizer.vue';


export default {
    components: {
        [Dialog.name]: Dialog,
        FilterOrganizer,
    },
      props: ["filters", 'url', 'title','modals','visible'],
    data() {
        return {
            currentFilter: [],
        }
    },
    mounted() {
        for(let filter of this.filters){
            if(!filter.permession ){
                this.currentFilter.push(filter);
            }

        }

    },
    methods: {
        exportItems() {
            let fileName = this.title+' Data ' + new Date().toLocaleDateString() + ".xlsx";
            this.axios.post(this.url,
            {searchQuery: this.searchQuery, filters: this.filters}, {responseType: 'blob'})
            .then((response) => {
            let url = URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            });
        },
        reset: function () {
            for(let filter of this.filters){
                filter.value = null ;
            }
        },
        closeModal (name) {
          this.$emit('close');
        },
    }
}
</script>

